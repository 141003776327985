<template>
  <v-card class="w-100 my-5 pa-11">
    <v-row align="center">
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="padding-end-zero">
          Ürün Adı:
        </v-list-subheader>
      </v-col>

      <v-col cols="12" md="10" class="pa-0">
        <v-text-field
          variant="outlined"
          v-model="productname"
          required
          :rules="[() => !!productname || 'Ürün adı boş bırakılamaz.']"
          :disabled="allDisabled"
          @keyup.enter="entryKeyupEnter"
        />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="padding-end-zero">
          Kategori:
        </v-list-subheader>
      </v-col>

      <v-col cols="12" md="10" class="pa-0">
        <v-select
          clearable
          :items="getCategories"
          variant="outlined"
          @click="
            () => {
              selectedSubCategory = '';
              selectedBrand = '';
            }
          "
          v-model="selectedCategory"
          required
          :rules="[() => !!selectedCategory || 'Kategori boş bırakılamaz.']"
          :disabled="allDisabled"
        />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="padding-end-zero">
          Alt Kategori:
        </v-list-subheader>
      </v-col>

      <v-col cols="12" md="10" class="pa-0">
        <v-select
          clearable
          variant="outlined"
          @click="selectedBrand = ''"
          :items="getSubCategories"
          v-model="selectedSubCategory"
          required
          :rules="[
            () => !!selectedSubCategory || 'Alt kategori boş bırakılamaz.',
          ]"
          :disabled="allDisabled"
        />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="padding-end-zero">
          Tedarikçi:
        </v-list-subheader>
      </v-col>

      <v-col cols="12" md="10" class="pa-0">
        <v-text-field
          variant="outlined"
          v-model="supplier"
          required
          :rules="[() => !!supplier || 'Tedarikçi boş bırakılamaz.']"
          :disabled="allDisabled"
          @keyup.enter="entryKeyupEnter"
        />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="padding-end-zero"> Marka: </v-list-subheader>
      </v-col>

      <v-col cols="12" md="4" class="pa-0">
        <v-select
          clearable
          variant="outlined"
          :items="getSubCategoriesWithBrand"
          v-model="selectedBrand"
          required
          :rules="[() => !!selectedBrand || 'Marka boş bırakılamaz.']"
          :disabled="allDisabled"
        />
      </v-col>
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="subheader-margin padding-end-zero">
          Birim:
        </v-list-subheader>
      </v-col>
      <v-col cols="12" md="4" class="pa-0">
        <v-select
          clearable
          variant="outlined"
          v-model="unit"
          :items="[
            'adet',
            'bağ',
            'boy',
            'cm',
            'çuval',
            'gr',
            'grup',
            'kg',
            'koli',
            'kutu',
            'lt',
            'm²',
            'm³',
            'm',
            'mm',
            'ml',
            'paket',
            'saat',
            'ton',
            'top',
          ]"
          required
          :rules="[() => !!unit || 'Birim boş bırakılamaz.']"
        />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="padding-end-zero"> Miktar: </v-list-subheader>
      </v-col>

      <v-col cols="12" md="4" class="pa-0">
        <v-text-field
          variant="outlined"
          v-model="quantity"
          required
          :rules="[
            () => !!quantity || 'Miktar boş bırakılamaz.',
            () =>
              /^\d+(\.\d+)?$/.test(quantity) ||
              'Lütfen yalnızca sayısal bir değer giriniz.',
          ]"
          @keyup.enter="entryKeyupEnter"
        />
      </v-col>
      <v-col cols="12" md="2" class="col-padding">
        <v-list-subheader class="subheader-margin padding-end-zero">
          Birim Fiyat:
        </v-list-subheader>
      </v-col>
      <v-col cols="12" md="4" class="pa-0">
        <v-text-field
          variant="outlined"
          v-model="unitprice"
          required
          :rules="[
            () => !!unitprice || 'Birim fiyat boş bırakılamaz.',
            () =>
              /^\d+(\.\d+)?$/.test(unitprice) ||
              'Lütfen yalnızca sayısal bir değer giriniz.',
          ]"
          @keyup.enter="entryKeyupEnter"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="5"
        offset="7"
        sm="3"
        offset-sm="9"
        md="2"
        offset-md="10"
        class="col-padding"
      >
        <v-btn
          :style="{
            'background-color': allTrue ? '#00c853' : '#ededed',
            'font-family': 'auto',
            width: '100%',
          }"
          :disabled="!allTrue"
          @click="entryClick"
        >
          Ekle
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { useToast } from "vue-toast-notification";
import { mapActions } from "vuex";

export default {
  props: {
    barcode: String,
    allDisabled: Boolean,
    entryProductname: String,
    entrySelectedCategory: String,
    entrySelectedSubCategory: String,
    entrySupplier: String,
    entrySelectedBrand: String,
  },

  data() {
    return {
      productname: "",
      selectedCategory: "",
      selectedSubCategory: "",
      supplier: "",
      selectedBrand: "",
      unit: "",
      quantity: "",
      unitprice: "",
      inventories: {
        "Meyve ve Sebze": {
          Meyve: [
            "Reyondan",
            "Verita",
            "Excelente",
            "Gürkan",
            "Del Monte",
            "Osmaniye",
            "Emsal",
            "M Life",
            "Genuine Coconut",
          ],
          Sebze: [
            "Reyondan",
            "Si&Ha",
            "Gurme212",
            "Eurofresh",
            "Dünya Hayat",
            "Garliko",
            "Agrotalya",
            "Yurtalan",
            "Canbaş",
            "Beyköy",
            "Verita",
            "Tasconi",
            "Aressa",
          ],
          "Yetiştirme Kiti": ["Osmanlı Bahçesi", "Reyondan", "Eden Zirai"],
        },
        "Et, Tavuk ve Balık": {
          "Kırmızı Et": [
            "Reyondan",
            "Uzman Kasap",
            "Pınar",
            "Superfresh",
            "Torku",
            "Gurvita",
            "Eral Et",
            "Tire",
            "Hasek",
            "Zel",
            "Orvital",
            "Tire Süt Kooperatifi",
            "Erşan",
            "Eral",
          ],
          "Beyaz Et": [
            "Gedik",
            "Banvit",
            "Beypiliç",
            "Şenpiliç",
            "Erpiliç",
            "Bolez",
            "Gözde",
            "Bolca",
            "Lezita",
            "Orvital",
            "Ant Bahar",
            "İzmir",
            "Reyondan",
            "Gurvita",
          ],
          "Balık ve Deniz Mahsülleri": [
            "Reyondan",
            "Balık Dünyası",
            "Dardanel",
            "Kocaman",
            "Pınar",
            "Red Tiger",
            "Conker",
            "Admirals",
            "Gusto",
            "Leroy",
            "Fish Point",
          ],
          "Et Şarküteri": [
            "Polonez",
            "Pınar",
            "Namet",
            "Erşan",
            "Apikoğlu",
            "Maret",
            "Başyazıcı",
            "Yayla Türk",
            "Torku",
            "Aytaç",
            "Fümeci",
            "Özlem",
            "Banvit",
            "Reyondan",
            "Cumhuriyet Sucukları",
            "Şahin",
            "Fulya",
            "Altın Dana",
            "Yayla",
            "Altın",
            "Keskinoğlu",
            "Laz Kızı",
            "Seçme",
          ],
        },
        "Kahvaltılık ve Süt Ürünleri": {
          Süt: [
            "İçim",
            "Pınar",
            "Fomilk",
            "Alpro",
            "Sek",
            "Danone",
            "Nilky",
            "Nestle",
            "VeganMilk",
            "Torku",
            "Dimes",
            "Activia",
            "Dulce De Leche",
            "Nesquik",
            "M Life",
            "Baltalı",
            "Efeler Çiftliği",
            "Orvital",
            "Alpimilk",
            "Elta Ada",
            "Milupa",
          ],
          Peynir: [
            "Tahsildaroğlu",
            "Pınar",
            "Bahçıvan",
            "İçim",
            "Ariste",
            "Muratbey",
            "La Vache Qui Rit",
            "Sek",
            "Taciroğlu",
            "President",
            "Doğruluk",
            "Altınkılıç",
            "Torku",
            "Elta Ada",
            "Hasmandıra",
            "Onsüt",
            "Kiri",
            "Golden Dutch",
            "Baltalı",
            "Ünal",
            "G.C. Gıda",
            "Akpınar",
            "Ünal Çiftliği",
            "Fomilk",
            "Lente",
            "Sütbon",
            "Ekici",
            "Milgo",
            "Anadolu Lezzetleri",
            "Laz Kızı",
            "Frico",
            "Cebir",
            "Veggy",
            "Sütaş",
            "Özışık",
            "Yardımcı",
            "Teksüt",
            "Biogurme",
            "P.E. 810",
            "Bergen",
            "Yeşil Kozan",
            "Karper",
            "Onur",
            "Primavera",
            "Koop",
            "Tire",
            "Orvital",
            "Clearspring",
            "Lactima",
            "Cebel",
            "Tire Süt Kooperatifi",
            "Alberto Di Centa",
            "Mandalinn",
            "Grandor",
            "Cibus",
            "Bergaz",
            "Gökçebayır",
            "Gündoğdu",
            "F.G",
            "Kervan",
            "Reyondan",
            "Peysan",
            "Philadelphia",
            "Savak",
            "Mersoy",
            "Kırıtaklar",
            "Cihanser",
            "Güneydoğu",
            "Efeler Çiftliği",
            "Meriç",
          ],
          Yoğurt: [
            "Activia",
            "Eker",
            "Danone",
            "İçim",
            "Sütaş",
            "Sek",
            "Arslan",
            "Pınar",
            "Elta Ada",
            "Torku",
            "Danino",
            "Tire Süt Kooperatifi",
            "Ateşoğlu",
            "Danem",
            "Nilky",
            "Belkıs Çiftliği",
            "Velioğlu",
          ],
          Tereyağı: [
            "Sek",
            "İçim",
            "Polonezköy",
            "S.S. Tire Organik Gıda Kooperatifi",
            "Laz Kızı",
            "Sütaş",
            "Pınar",
            "Tahsildaroğlu",
            "Ekici",
            "Kurpu",
            "Yörük Çiftliği",
            "Enka",
            "Elta Ada",
            "Kanlıca",
            "Hasmandıra",
            "Tire Süt Kooperatifi",
          ],
          Margarin: ["Teremyağ", "Becel", "Sana", "Bizim", "Sabah", "Lurpak"],
          Yumurta: [
            "Yumurtacım",
            "Coşkun",
            "Doğalım",
            "Raya",
            "Flotty",
            "Güres",
            "Naturaköy",
            "Orvital",
            "M Life",
            "Kor",
            "Green Ranch",
            "Gürata",
            "Tire",
            "Ordu Yumurta Üreticileri Birliği",
          ],
          Zeytin: [
            "Fora",
            "Marmarabirlik",
            "Cem Zeytin",
            "Zertum",
            "Cem",
            "Komili",
            "Adra",
            "Öncü",
            "Nutruit Olipop",
            "İlyada",
            "Bağcı",
            "Gürağaç",
            "Çukurbahçe",
            "Taneli",
            "Marmara Birlik",
            "Seleköy",
            "Oli Black",
          ],
          "Sütlü Tatlı ve Krema": [
            "Eker",
            "Dr.Oetker",
            "Eti",
            "Danette",
            "Kinder",
            "Fo",
            "Sek",
            "Pınar",
            "Pakmaya",
            "İçim",
            "Tikveşli",
            "Kenton",
            "Altın",
            "Oreo",
            "President",
            "Hulala",
            "Belkıs Çiftliği",
            "Milka",
            "Velioğlu",
            "Danissimo",
            "Danone",
            "Kısmet",
            "Green Way",
          ],
          Kahvaltılıklar: [
            "Balparmak",
            "Koska",
            "Yenigün",
            "Eti",
            "Patiswiss",
            "Beeo",
            "Kellogg's",
            "Tamek",
            "M Life",
            "Torku",
            "Nesquik",
            "Tat",
            "Nestle",
            "Fiskobirlik",
            "Sarelle",
            "Nesfit",
            "Anavarza",
            "Anadolu Lezzetleri",
            "Fellas",
            "Nustil",
            "Nut Master",
            "Züber",
            "Jif",
            "Fitnut",
            "City Farm",
            "Kühne",
            "Dr.Oetker",
            "Reyondan",
            "Penguen",
            "Fropie",
            "Canpetek",
            "Sakarkaya",
            "Emsal",
            "Melis",
            "Kaldera",
            "Berrak",
            "Nutella",
            "Ganik",
            "Richland",
            "Lotus",
            "Gold",
            "Noi",
            "Tukaş",
            "Burcu",
            "Chokella",
            "Crunch",
            "By İzzet",
            "Master Nut",
            "Schar",
            "Ülker",
            "Origin",
            "Weetabix",
            "Çokokrem",
            "Antebella",
            "Sinangil",
            "Abdurrahman Tatlıcı",
            "Şenocak",
          ],
        },
        "Temel Gıda": {
          Makarna: [
            "Barilla",
            "Pastavilla",
            "indomie",
            "Mutlu Makarna",
            "Arbella",
            "Filiz",
            "Nudo",
            "Veronelli",
            "Nuh'un Ankara",
            "Dudomi",
            "Knorr",
            "Gurmezade",
            "Arrighi",
            "Un Do Tre",
            "Forcella",
            "Ekin",
          ],
          Bakliyat: [
            "Yayla",
            "Reis",
            "Tat",
            "Hasata",
            "Duru Bulgur",
            "M Life",
            "Doyum",
            "Anadolu Lezzetleri",
            "S.S. Tire Organik Gıda Kooperatifi",
            "Filiz",
            "Torku",
            "İstiklal Yolu",
            "Yayla Türk",
          ],
          "Sıvı Yağ": [
            "Komili",
            "Yudum",
            "Kristal",
            "Oneva",
            "Verde",
            "Sırma",
            "Orkide",
            "Tariş",
            "Anadolu Hazineleri",
            "Kırlangıç",
            "Güven Asa",
            "Çotanak",
            "Avofarm",
            "Turna",
            "Olitalia",
            "Nermin Hanım Zeytinliği",
            "Abalı",
            "Torku",
            "Marmarabirlik",
            "Hüner",
            "Asiltane",
          ],
          "Tuz, Baharat ve Harç": [
            "Bağdat",
            "Arifoğlu",
            "Knorr",
            "Reyondan",
            "Billur",
            "Efsina",
            "Ayfer Kaur",
            "Baharat Dünyası",
            "Dünya Hayat",
            "Salina",
            "Si&Ha",
            "Anadolu Lezzetleri",
            "Müsan",
            "Purelife",
            "Bizim",
            "Yayla",
            "M Life",
            "City Farm",
            "Melis",
            "Tariş",
          ],
          Bulyon: ["Knorr", "Bizim", "Fide", "Veg & Bones", "Zel"],
          Konserve: [
            "Tat",
            "Dardanel",
            "Kühne",
            "Burcu",
            "Superfresh",
            "Yayla",
            "Berrak",
            "İpek",
            "Fide",
            "Tamek",
            "Penguen",
            "Ant Bahar",
            "Reyondan",
            "Tukaş",
            "Gurme",
            "Öncü",
            "Melis",
            "Pınar",
            "Talia",
            "Tada",
            "Demko",
            "Kalite Tonla",
            "Yurt Konserve",
            "Sera",
            "Anadolu Lezzetleri",
            "Gurme212",
            "M Life",
          ],
          Sos: [
            "Kühne",
            "Calve",
            "Tat",
            "Fersan",
            "Heinz",
            "Pınar",
            "Doğanay",
            "Knorr",
            "Tukaş",
            "Jr.Sous Chef",
            "Burcu",
            "Barilla",
            "Tabasco",
            "Tariş",
            "Uzman Kasap",
            "Hp",
            "Maille",
            "Remia",
            "Pastavilla",
            "Ferfresh",
            "Kikkoman",
            "Lowensenf",
            "Teptip",
            "Penguen",
            "King Crown",
            "Raz",
          ],
          Un: [
            "Söke",
            "Sinangil",
            "Eriş",
            "Bemtat",
            "Bünsa",
            "İnci",
            "Anadolu Lezzetleri",
            "Güzel Ada Gıda",
            "Glutensiz Ada",
            "İstiklal Yolu",
            "Oneva",
            "M Life",
            "Schar",
            "Bağdat",
          ],
          Şeker: ["Torku", "Irmak", "Bor Şeker", "Dr.Oetker", "Takita"],
          "Sağlıklı Yaşam Ürünleri ": [
            "M Life",
            "Fellas",
            "Takita",
            "Doyum",
            "Züber",
            "Bio Protein",
            "Muscle Station",
            "Uniq2go",
            "Schar",
            "Orgalife",
            "Canderel",
            "City Farm",
            "Eti",
            "Tada",
            "Bemtat",
            "Sinangil",
            "Glutensiz Ada",
            "Güzel Ada Gıda",
            "Jr.Sous Chef",
            "Tchibo",
            "Delly",
            "Veggy",
            "Yayla",
            "Barilla",
            "Söke",
            "Saka",
            "Tat",
            "Powerade",
            "Bigjoy",
            "Kaldera",
            "Heinz",
            "Çaykur",
            "S.S. Tire Organik Gıda Kooperatifi",
            "The Lifeco",
            "Ant Bahar",
            "Huxol",
            "Anadolu Hazineleri",
            "Pastavilla",
            "Kristal",
            "Karali",
            "Kühne",
            "Calve",
          ],
        },
        "Meze, Hazır Yemek ve Donuk": {
          Meze: [
            "Reyondan",
            "Gurumen",
            "Mezzetürk",
            "Beypazarı",
            "Biberes",
            "Mister No",
          ],
          "Paketli Sandviç": ["Tada", "Mister No", "Mr.No"],
          "Pratik Yemek": [
            "Yayla",
            "Knorr",
            "Tada",
            "Dardanel",
            "Zel",
            "Bizim",
            "Tat",
            "Gurmepack",
            "Yurt Konserve",
            "Tamek",
            "Burcu",
            "Veg & Bones",
            "Fide",
            "Veggy",
            "Gurme212",
            "Gurme",
            "G.C. Gıda",
            "Anadolu Lezzetleri",
            "Tukaş",
          ],
          "Dondurulmuş Gıda ": [
            "Superfresh",
            "Feast",
            "Muti Gıda",
            "Pınar",
            "Pek Food",
            "Lavi",
            "Embi",
            "Elmasoğlu",
            "Dr.Oetker",
            "Pizza Mi Go",
            "Galez",
            "Privegi",
            "Nelipide",
            "Veggy",
            "Yeşil",
            "Torpat",
            "Sarıvadi",
            "Toblerone",
            "Milka",
            "Başyazıcı",
            "Serez",
          ],
        },
        İçecek: {
          "Gazlı İçecek": [
            "Coca Cola",
            "Red Bull",
            "Pepsi",
            "Uludağ",
            "Schweppes",
            "Fanta",
            "Çamlıca",
            "Sprite",
            "Monster",
            "Yedigün",
            "Maltana",
            "Burn",
            "Coca-Cola",
            "Fruko",
            "Black Bruin",
            "Fever Tree",
            "Dydo",
            "Mountain Dew",
            "Beyoğlu Gazozu",
            "Joozy",
            "Seven Up",
            "Peja",
            "Haliç Altın",
          ],
          "Gazsız İçecek": [
            "Dimes",
            "Cappy",
            "Tamek",
            "Eker",
            "Elite Naturel",
            "Altınkılıç",
            "Uludağ",
            "Juss",
            "İçim",
            "Doğanay",
            "Sütaş",
            "Sek",
            "Danem",
            "The Good Wild",
            "Herby",
            "Pınar",
            "Serafresh",
            "Danone",
            "M Life",
            "Sarıyer",
            "Torku",
            "Kavaklıdere",
            "Arslan",
            "Vivo",
            "Orvital",
            "Serfresh",
            "Elta Ada",
            "High Five",
            "Balaban",
            "Vefa",
            "Pin",
            "DK",
            "Naturel",
            "Limmo",
            "Fo",
            "Tat",
          ],
          Çay: [
            "Lipton",
            "Doğadan",
            "Doğuş",
            "Fuse Tea",
            "Çaykur",
            "Efor",
            "Herby",
            "Didi",
            "Ahmad Tea",
            "Si&Ha",
            "Teekanne",
            "Karali",
            "M Life",
            "Orgalife",
            "Tamek",
            "Dünya Hayat",
            "Pin",
            "Ofçay",
            "Pin Drinks",
            "Güzel Çay",
            "Juss",
            "Entschlackungstee",
            "Layaline",
          ],
          Kahve: [
            "Nescafe",
            "Tchibo",
            "Jacobs",
            "Starbucks",
            "Cafe Crown",
            "Kurukahveci Mehmet Efendi",
            "Davidoff",
            "The Whirl",
            "İstanbul Kahve",
            "Dr.Oetker",
            "Obsesso",
            "Coffee Mate",
            "Lor",
            "Nesquik",
            "Cezbeli",
            "Kocatepe",
            "Kronotrop",
            "Lor Espresso",
            "Shazel",
            "Nestle",
            "Hisar Kahve",
            "Dripesso",
            "Zel",
            "Caffe Milano",
            "Cafe Time",
            "Danette",
            "Sütaş",
            "Sek",
            "Sahure Hanım",
            "My Coffee",
            "Tikveşli",
            "Coffee Time",
          ],
          Su: [
            "Erikli",
            "Pınar",
            "Nestle",
            "Damla",
            "Mila",
            "Saol",
            "Hayat",
            "Saka",
            "Uludağ",
            "Abant",
            "Assu",
            "Sırma",
            "Hamidiye",
            "Pürsu",
          ],
          "Maden Suyu": [
            "Sırma",
            "Frutti",
            "Damla",
            "Kızılay",
            "Saka",
            "Akmina",
            "San Pellegrino",
            "Pınar",
            "Kınık",
            "Uludağ",
            "Terme Di Crodo",
            "Beypazarı",
            "Perrier",
          ],
        },
        Dondurma: {
          "Kap Dondurma": [
            "Carte d'Or",
            "Algida",
            "Mövenpick",
            "Golf",
            "Bauvian",
            "Arzen",
            "Royal",
            "Viennetta",
            "Magnum",
            "Bravo",
          ],
          "Tek Dondurma": [
            "Magnum",
            "Bravo",
            "Algida",
            "Cornetto",
            "Max",
            "Koska",
            "Classics",
            "Eti",
            "Golf",
            "Alaska Frigo",
            "Bounty",
            "Nogger",
            "Mars",
            "Roko",
            "Snickers",
            "Twix",
            "Frigola",
          ],
          Buz: ["Buzlat", "Icexperience"],
        },
        Atıştırmalık: {
          "Kuru Meyve": [
            "Reyondan",
            "Peyman",
            "Master Farm",
            "Tadım",
            "Dovido",
            "Asha",
            "Otto Nuts",
            "By İzzet",
            "Emsal",
            "Quru",
            "Kırlıoğlu",
            "Noi",
            "Pol's",
            "M Life",
            "Kara Sevda",
            "Şenocak",
          ],
          "Kuru Yemiş": [
            "Reyondan",
            "Tadım",
            "Peyman",
            "List Nuts",
            "Master Nut",
            "Kara Sevda",
            "Emsal",
            "Bobo",
            "By İzzet",
            "Fropie",
            "Şenocak",
            "Avuç Avuç",
            "Anadolu Lezzetleri",
            "Noi",
            "Çitliyo",
            "Bobo Nuts",
            "Siirt'ten",
          ],
          Cips: [
            "Doritos",
            "Lay's",
            "Çerezza",
            "Ruffles",
            "Cheetos",
            "Patos",
            "Çerezos",
            "Züber",
            "Patiswiss",
            "M Life",
            "Peyman",
            "Eti",
            "Vegeat's",
            "Chips Coin",
            "Pol's",
            "Chips Master",
            "Master",
            "Nutzz",
            "Cipso",
            "Bio Protein",
            "Anadolu Lezzetleri",
            "Reyondan",
          ],
          Çikolata: [
            "Eti",
            "Ülker",
            "Patiswiss",
            "Nestle",
            "Milka",
            "Tadelle",
            "Ozmo",
            "Godiva",
            "Lindt",
            "Kinder",
            "Elit",
            "Toblerone",
            "Torku",
            "Milango",
            "Damak",
            "Şölen",
            "Caramio",
            "M&M's",
            "Maltesers",
            "Maitre Truffout",
            "Mcvitie's",
            "Albeni",
            "Çokokrem",
            "Lovells",
            "Toto",
            "Bonart",
            "Grand",
            "Schogetten",
            "Kahve Dünyası",
            "Toffifee",
            "Ferrero",
            "Ritter Sport",
            "Reyondan",
            "Jimmy",
            "Yupo",
          ],
          Gofret: [
            "Ülker",
            "Biscolata",
            "Eti",
            "Çizmeci Time",
            "9 Kat Tat",
            "Torku",
            "Nestle",
            "Tadelle",
            "Nutymax",
            "Boombastic",
            "Sarelle",
            "Luxens",
            "Dido",
            "Balocco",
            "Kit Kat",
            "Ritter",
            "Kinder",
            "Mcvitie's",
            "Milka",
            "Çokonat",
            "Kahve Dünyası",
            "Alpella",
            "Ivorie",
            "Zeyrek",
          ],
          "Bar ve Kaplamalılar": [
            "Züber",
            "Eti",
            "Mixmey",
            "Ülker",
            "Fellas",
            "Patiswiss",
            "Nesfit",
            "Fropie",
            "Delly",
            "Albeni",
            "M Life",
            "Nestle",
            "Malty",
            "Twix",
            "Peyman",
            "Cino",
            "Torku",
            "Metro",
            "Nesquik",
            "Corny",
            "Coco Star",
            "Snickers",
            "Piko",
            "Laviva",
            "Miskos",
            "Ozmo",
            "Bounty",
            "Mars",
            "Koska",
          ],
          Bisküvi: [
            "Eti",
            "Ülker",
            "Torku",
            "Oreo",
            "Biscolata",
            "Pötibör",
            "Milka",
            "Halley",
            "Luppo",
            "Şölen",
            "Biskrem",
            "Mcvitie's",
            "Çokoprens",
            "Hanımeller",
            "Lotus",
            "Divan",
            "Altınbaşak",
            "İkram",
            "Annas",
            "Ozmo",
            "Aldiva",
            "Saklıköy",
            "Albeni",
            "Coco Star",
            "Weiss",
            "Patiswiss",
            "Leibniz",
            "Haylayf",
            "Lambertz Gingerbread",
            "Dankek",
            "Çizmeci Time",
            "Probis",
          ],
          Kek: [
            "Eti",
            "Dankek",
            "O'Lala",
            "Kekstra",
            "7 Days",
            "Biscolata",
            "Ozmo",
            "8 Kek",
            "Torku",
            "Alpella",
            "Albeni",
          ],
          Kraker: [
            "Eti",
            "Ülker",
            "Çizi",
            "Hanımeller",
            "Krispi",
            "Greta",
            "Torku",
            "Seedn",
            "Patiswiss",
          ],
          Şekerleme: [
            "Reyondan",
            "Haribo",
            "Lokum Atölyesi",
            "Olips",
            "Uzungil",
            "Açıkel",
            "Yupo",
            "Jelibon",
            "Sultan ",
            "Anadolu Lezzetleri",
            "Koska",
            "Lokum Akademisi",
            "Chupa Chups",
            "Kent",
            "Rocco",
            "Kremini",
            "Tofita",
            "Skittles",
            "Divan",
            "Polo",
            "Topitop",
            "Missbon",
            "Jox",
            "Durukan Şekerleme",
            "Mentos",
            "Lolliboni",
            "Burak",
            "Toybox",
            "Bonbon",
            "Pez",
            "Jelly Bean",
            "Mom",
            "U Pops",
          ],
          Sakız: [
            "Vivident",
            "Oneo",
            "First",
            "Falım",
            "Tipitip",
            "Big Babol",
            "Bubblicious",
            "Mentos",
            "Orbit",
            "Toybox",
            "Yıldız",
            "Ülker",
            "Nazar",
            "Wrigley's",
            "Şıpsevdi",
          ],
        },
        "Fırın ve Pastane": {
          Ekmek: [
            "Reyondan",
            "Uno",
            "Unabella",
            "İhe",
            "Aly",
            "Mayaköy",
            "İyilik",
            "Hamurdan",
            "Backhaus",
            "Lavalia",
            "M Life",
          ],
          "Sabah Sıcakları": ["Unİstanbul", "Reyondan"],
          "Hamur ve Pasta Malzemeleri": [
            "Dr.Oetker",
            "Pakmaya",
            "Kenton",
            "Ülker",
            "Altın",
            "Uno",
            "Bağdat",
            "Sinangil",
            "Kor",
            "Fo",
            "Bemtat",
            "Arifoğlu",
            "Unabella",
            "Balocco",
          ],
          "Yufka, Erişte ve Mantı": [
            "Mantı-ye",
            "Reyondan",
            "Bereket",
            "Myfresh",
            "Unabella",
            "Şahin",
          ],
          "Kuru Pasta": [
            "Alaçatı Ev Kurabiyecisi",
            "Reyondan",
            "Tatbak",
            "Myfırın",
            "Mardinden",
            "Hamurdan",
            "Uno",
            "İhe",
            "Anne Kalbi",
          ],
          Pasta: ["Reyondan", "Coquette"],
          "Galeta, Grissini ve Gevrek": [
            "Eti",
            "Uno",
            "Wasa",
            "Arı Grissini",
            "Unabella",
            "Hamurdan",
            "Reyondan",
            "Unİstanbul",
            "Altınbaşak",
            "Mayaköy",
            "Tatbak",
            "Anadolu Lezzetleri",
            "Balıkçıoğlu",
            "Arı",
            "Ülker",
          ],
          Tatlı: [
            "Dr.Oetker",
            "Pakmaya",
            "Kenton",
            "Reyondan",
            "Ege Glutensiz",
            "Saffet Abdullah",
            "Altın",
            "Hamurdan",
            "Kral",
          ],
        },
        "Deterjan ve Temizlik": {
          "Çamaşır Yıkama": [
            "Bingo",
            "Omo",
            "Yumoş",
            "Ariel",
            "Domestos",
            "Vernel",
            "Vanish",
            "Persil",
            "Perwoll",
            "Ace",
            "Frosch",
            "Alo",
            "Renax",
            "Peros Matik",
            "Rinso",
            "Just Green",
            "Duru",
            "Lysol",
            "Tursil",
            "Calgon",
            "Marc",
            "Aquabor",
            "Peros",
            "K2R",
            "Ernet",
          ],
          "Bulaşık Yıkama": [
            "Finish",
            "Fairy",
            "Pril",
            "Frosch",
            "Just Green",
            "Turmepa",
            "Bingo",
            "Peros",
          ],
          "Genel Temizlik": [
            "Cif",
            "Porçöz",
            "Glade",
            "Mr Muscle",
            "Domestos",
            "Ernet",
            "Airwick",
            "Bref",
            "Green World",
            "Sitil",
            "Camsil",
            "Yumoş",
            "Asperox",
            "Mr.Muscle",
            "Frosch",
            "Bingo",
            "Ace",
            "Pereja",
            "Cillit Bang",
            "Raid",
            "Nazar",
            "Vanish",
            "Pronto",
            "Febreze",
            "Newo",
            "Clonet",
            "Selsil",
            "Jet",
            "Marc",
            "Luur",
            "Just Green",
            "Bion",
            "Shine Bloom",
            "Ajax",
            "Dixi",
            "Asprin",
            "Clin",
            "Odorfin",
            "Lysol",
            "Defans",
            "Turmepa",
            "Farell",
            "Parex",
            "Fairy",
            "Goddards",
            "Feat",
            "Tukaka",
          ],
          "Temizlik Malzemeleri": [
            "Parex",
            "Macromax",
            "Koroplast",
            "Bella",
            "A-plex",
            "Scotch Brite",
            "Roll-Up",
            "Sleepy",
            "Klen Hauss",
            "Solo",
            "Flex",
            "Reyondan",
            "Turco",
            "A Plex",
            "Next Bio",
            "Proff",
            "Banat",
            "Next",
            "Mr Oxy",
            "Familia",
          ],
          "Banyo Gereçleri": [
            "Mistique Home",
            "Leva",
            "Evvon",
            "Bee Home",
            "Bosphorus",
            "Farist Home",
            "Reyondan",
            "Koopman",
            "Bestchoice",
            "Evmoni",
            "Farist Bath",
            "Keramika",
            "Lova",
            "Viamart",
          ],
          "Çamaşır Gereçleri": [
            "Command",
            "Perilla",
            "Leva",
            "Magic Saver",
            "Ege",
            "Mistique Home",
            "Granit",
            "3M",
            "Ckc",
            "Huub",
            "Bee Home",
            "Bestchoice",
            "Evvon",
            "Sia",
            "Boss Tape",
            "Yıldız",
            "Lady",
            "Qlux",
            "Touch Me",
            "My Hanger",
            "Viamart",
          ],
          "Çöp Poşeti": ["Koroplast", "Cook", "İdeal", "Macromax", "Astra"],
          "Özel Paketler": ["Parex"],
        },
        "Kağıt ve Islak Mendil": {
          "Islak Mendil": [
            "Uni Baby",
            "Sleepy",
            "Prima",
            "Selpak",
            "Minies",
            "Molfix",
            "Dalin",
            "Activex",
            "Hops",
            "Komili",
          ],
          "Tuvalet Kağıdı": [
            "Papia",
            "Sofia",
            "Selpak",
            "Familia",
            "Solo",
            "Viva",
            "Mare",
            "Ecco Soft",
            "Ecco",
            "Maylo",
            "Minies",
          ],
          "Kağıt Havlu": [
            "Sofia",
            "Solo",
            "Selpak",
            "Maylo",
            "Papia",
            "Familia",
            "Mare",
            "Ecco",
            "Viva",
            "Ecco Soft",
          ],
          Peçete: [
            "Selpak",
            "Duni",
            "Solo",
            "Sofia",
            "Familia",
            "Viva",
            "Dunilin",
            "Servis",
            "Ecco",
            "Maylo",
          ],
          "Kağıt Mendil": [
            "Selpak",
            "Sofia",
            "Maylo",
            "Kleenex",
            "Komili",
            "Viva",
          ],
        },
        "Kişisel Bakım, Kozmetik ve  Sağlık": {
          "Güneş Bakım": [
            "Cireaseptine",
            "Nivea",
            "Wemara",
            "Beeo",
            "Mara",
            "Carroten",
            "Sebamed",
            "Minela Care",
            "Eda Taşpınar",
          ],
          "Hijyenik Ped": [
            "Orkid",
            "Kotex",
            "Molped",
            "Sleepy",
            "O.B",
            "Carefree",
            "Tampax",
            "Deep Fresh",
          ],
          "Ağız Bakım Ürünleri": [
            "Colgate",
            "Oral-B",
            "Sensodyne",
            "Signal",
            "Difaş",
            "Banat",
            "Listerine",
            "Parodontax",
            "Biomed",
            "Ipana",
            "Rocs",
            "Pearl Drops",
            "Corega",
            "Piknik",
            "Dermokil",
            "Fixodent",
            "Himalaya",
            "T-brush",
            "Stopever",
          ],
          "Saç Bakım Ürünleri": [
            "L'Oreal Paris",
            "Pantene",
            "Elidor",
            "Garnier",
            "Palette",
            "Head&Shoulders",
            "Gliss",
            "Koleston",
            "Urban Care",
            "Difaş",
            "Clear",
            "Hobby",
            "OGX",
            "Banat",
            "Herbal Essences",
            "Dove",
            "John Frieda",
            "Viored",
            "Egos",
            "Tints Of Nature",
            "Blendax",
            "Tresan",
            "Syoss",
            "Taft",
            "Sebamed",
            "Bioblas",
            "Just For Men",
            "Revox",
            "Cosnature",
            "Men Perfect",
            "Ziaja",
            "Dp",
            "Restorex",
            "Flamingo",
            "Dermokil",
            "Dax",
            "Wemara",
            "Eder",
            "Mixup",
            "Morfose",
            "Lionesse",
            "Ambiente",
          ],
          "Duş, Banyo ve Sabun": [
            "Palmolive",
            "Duru",
            "Le Petit Marseillais",
            "Dove",
            "Dalan",
            "Hacı Şakir",
            "Balmy Naturel",
            "Savon De Royal",
            "Old Spice",
            "Peros",
            "Protex",
            "Fax",
            "Pereja",
            "Sleepy",
            "Activex",
            "Johnson's Baby",
            "Soho NYC",
            "Deep Fresh",
            "The Soap Factory",
            "Olivos",
            "Nivea",
            "Mara",
            "Just Green",
            "Dermokil",
            "Kırlangıç",
            "Sebamed",
            "Frosch",
            "Head&Shoulders",
            "L'Oreal Paris",
            "Turmepa",
            "Loofah",
          ],
          "Tıraş Malzemeleri": [
            "Gillette",
            "Arko",
            "Derby",
            "Bic",
            "Nivea",
            "Permatik",
            "Just For Men",
          ],
          "Ağda ve Epilasyon": [
            "Veet",
            "Vi-Vet",
            "Gillette Venus",
            "Remove",
            "Gillette",
            "Bic",
            "Like Me",
            "Balmy Naturel",
            "Lionesse",
            "Derby",
            "Jolly",
            "Promani",
            "Banat",
          ],
          "Cilt Bakımı": [
            "Nivea",
            "Neutrogena",
            "L'Oreal Paris",
            "Arko",
            "Bepanthol",
            "Garnier",
            "Himalaya",
            "Cireaseptine",
            "Dr.Smart",
            "Dermokil",
            "Mara",
            "Sebamed",
            "Beeo",
            "Maruderm",
            "Simple",
            "Tonymoly",
            "Ziaja",
            "Bio-Oil",
            "Like Me",
            "Voop",
            "Siveno",
            "Krauterhof",
            "Hobby",
            "Claderm",
            "Vaseline",
            "Altermed",
            "Mixup",
            "Johnson's Baby",
            "Maybelline New York",
            "Eda Taşpınar",
            "Uni Care",
            "Tomy",
            "Flormar",
            "Talya",
            "Botanica",
            "Stopever",
          ],
          Kolonya: [
            "Selin",
            "Pereja",
            "Duru",
            "Johnson's Baby",
            "Önder",
            "Boğaziçi",
            "Rebul",
          ],
          "Parfüm, Deodorant": [
            "Nivea",
            "Rexona",
            "Emotion",
            "Garnier",
            "Blade",
            "Old Spice",
            "Axe",
            "Dreamland",
            "L'Oreal Paris",
            "Fa",
            "Privacy",
            "Antonio Banderas",
            "Dove",
            "Mara",
            "Deotak",
            "Lolita",
            "Eda Taşpınar",
            "Aqua Di Polo",
            "Ossia",
            "Bellisima",
            "First Class",
            "Beeo",
            "Cireaseptine",
            "Pereja",
            "Siveno",
            "Sebamed",
          ],
          Makyaj: [
            "Maybelline New York",
            "Golden Rose",
            "Flormar",
            "Essence",
            "L'Oreal Paris",
            "Pastel",
            "Note",
            "Mara",
            "Garnier",
            "Mixup",
            "Nivea",
            "Lüx",
            "Like Me",
            "Lush Lash",
            "Şelale",
            "İpek",
            "Neutrogena",
            "Johnson's Baby",
            "Nascita",
            "Cotoneve",
            "Cotton Soft",
            "Cle Up",
            "Flamingo",
            "Color Tattoo",
          ],
          "Sağlık Ürünleri": [
            "Durex",
            "Talya",
            "Okey",
            "Canped",
            "Nature's Supreme",
            "Arifoğlu",
            "Voop",
            "Tabanex",
            "Getwell",
            "Lionesse",
            "Naturagen",
            "Ülker",
            "Stopever",
            "One Up",
            "Depend",
            "Vitago",
            "Sleepy",
            "Silky Kiss",
            "Boos+",
            "The Lifeco",
            "Banat",
            "Neutrogena",
            "Prosafe",
            "İpek",
            "Vitagil",
            "Dr.Plus",
            "Süper Süzen",
            "Qlife",
            "Beeo",
            "Önce",
            "Lüx",
            "Promani",
            "Breathe Right",
            "Mara",
            "Johnson's Baby",
            "Cotton Soft",
            "Cle Up",
            "Balmy Naturel",
            "Vlamor",
            "Bepanthol",
            "Tonymoly",
            "Cireaseptine",
            "Nascita",
            "Triogermila-ı",
            "Alvin",
          ],
          "Özel Paketler": ["Wemara"],
        },
        Bebek: {
          "Bebek Bezi": [
            "Prima",
            "Sleepy",
            "Molfix",
            "Minies",
            "Goo.n",
            "Huggies",
            "Goon",
            "Canped",
          ],
          "Bebek Bakım": [
            "Dalin",
            "Johnson's Baby",
            "Bepanthol",
            "Uni Baby",
            "Chicco",
            "İpek",
            "Wee Baby",
            "Mycey",
            "Dentavit",
            "Sudocrem",
            "Sebamed",
          ],
          "Bebek Beslenme": [
            "Hipp",
            "Wee Baby",
            "Bebelac",
            "Aptamil",
            "Sma",
            "Humm",
            "Hero Baby",
            "Gerber",
            "Milupa",
            "Veg & Bones",
            "Chicco",
            "Arılac",
            "Arı",
          ],
          "Bebek Banyo": [
            "Johnson's Baby",
            "Dalin",
            "Uni Baby",
            "Sebamed",
            "Johnsons&Johnsons",
            "Komili",
            "Bepanthol",
            "Balmy Naturel",
            "Minies",
          ],
          "Bebek Deterjanı ve Yumuşatıcı": [
            "Uni Baby",
            "Dalin",
            "Omo",
            "Yumoş",
            "Roxy",
            "Frosch",
            "Dalan",
            "Hacı Şakir",
            "Minies",
          ],
          "Bebek Tekstil": ["Kardelen"],
          "Anne Ürünleri": ["Lactamil", "Doğadan"],
        },
        "Ev ve Yaşam": {
          "Mutfak Eşyaları": [
            "Paşabahçe",
            "Lav",
            "Roll-Up",
            "Tulu",
            "Evmoni",
            "Mehtap",
            "Metaltex",
            "Keramika",
            "Koroplast",
            "Touch Me",
            "Cook",
            "Propack",
            "Victorinox",
            "Kütahya Porselen",
            "Borcam",
            "Viamart",
            "Titiz",
            "Ece",
            "Tefal",
            "Aryıldız",
            "Papilla",
            "Rakle",
            "Brita",
            "Luminarc",
            "Ultraform",
            "Hisar",
            "Fine Home",
            "Akdeniz",
            "Dolphin",
            "Albayrak",
            "Bee Home",
            "Cem",
            "Macromax",
            "Pirge",
            "Antonio Banderas",
            "Cookc",
            "Cambu",
            "Tivoli",
            "Emiva",
            "Bambum",
            "Axentia",
            "Zwilling",
            "Reyondan",
            "Goldstar",
            "Ckc",
            "İdeal",
            "Güral Porselen",
            "Astra",
            "Viapot",
            "Vaeske",
            "Guardini",
            "Koopman",
            "İncir Home",
            "Leva",
            "Pratik",
            "Vip",
            "Myros",
            "Qlux",
            "Sürel",
            "Mistique Home",
            "Sinbo",
            "Renga",
            "Royal",
            "Masterchef",
            "Trend Glass",
            "Irak Plastik",
            "Frocx",
            "Merkant",
            "Mulier",
            "Kiwi",
            "Herevin",
            "Atlantic",
            "i'think",
            "Lava",
            "Kaiser",
            "Sebastian",
            "Testrut",
            "Perilla",
            "Casa Linda",
            "Globox",
            "My Bottle",
            "Plastlife",
            "Juno",
            "Tohana",
            "Kral Şakir",
            "KLT",
            "Uzman Kasap",
            "Minnie",
            "Savora",
            "Pure Blanche",
            "Simax",
            "Sarkap",
            "Philips",
            "Harry Potter",
            "Chefpack",
            "Saban",
            "Sun Plastik",
            "Albayrak Melamın",
            "Plastart",
            "Barbie",
            "Gondol",
            "Paw Patrol",
            "Rota",
            "Top Star",
            "Kuleli",
            "Masse Handy",
          ],
          "Mobilya ve Dekorayon": [
            "Snow Angels",
            "Balonevi",
            "Mistique Home",
            "Frocx",
            "Leva",
            "Belde",
            "Evvon",
            "Myros",
            "Reyondan",
            "Balon Evi",
            "Touch Me",
            "Viamart",
            "General Camp",
            "Ruum",
            "Roll-Up",
            "Koopman",
            "Umur",
            "Paşabahçe",
            "Antonio Banderas",
            "Caribbo",
            "Plastart",
            "Ege",
            "Artikel",
            "Granit",
            "Vimart",
            "Poliwork",
            "Eser",
            "Evmoni",
            "Mercan",
            "Farist Home",
            "Mystyle",
            "Mabbels",
            "Fine Home",
            "Lav",
            "Bambum",
            "Keramika",
            "Bayrakçı",
            "Unicorn",
            "Gondol",
            "Pj Masks",
            "Kral Şakir",
            "My Style",
            "Modalife",
            "Titiz",
            "Atelier",
          ],
          "Bahçe ve Piknik Malzemeleri": [
            "Leva",
            "Reyondan",
            "Poliwork",
            "Gardenzone",
            "Dörtel",
            "İyibahçe",
            "Kale",
            "Farist Garden",
            "Safran",
            "Torflower",
            "Mistique Home",
            "Duraflame",
            "Garden Time",
            "Umet",
            "Eco Reform",
            "Solo Plant",
            "Ece",
            "Mangalbaşı",
            "Bestchoice",
            "Grill Time",
            "Touch Me",
            "Guruss",
            "Eden Zirai",
            "Tropikal",
            "Kormatik",
            "Tarım Sepeti",
          ],
          "Spor ve Outdoor": [
            "Bestway",
            "Proforce",
            "Wst",
            "RKS",
            "Dynamic",
            "Raboom",
            "Çınarteks",
            "Transval",
            "Mistique Home",
            "Reyondan",
            "East Marine",
            "Voit",
            "Nk",
            "Nasa",
            "Triathlon",
            "Luxin",
            "Tvl Home",
            "Fossil",
            "Ezelisa",
            "Leva",
            "Joysidea",
            "Skyjet",
            "Volta",
          ],
          "Ev Tekstili": [
            "Tuva",
            "Chysis",
            "Samteks",
            "Maisonette",
            "Homebasix",
            "Elanore",
            "Favore",
            "Tvl Home",
            "Mollie Home",
            "Scotch And Brothers",
            "My Evilla",
            "Dinarsu",
            "Reyondan",
            "Ender Home",
            "Evde Home",
            "Antonio Banderas",
            "Medicaform",
            "Tokstill",
            "Viamart",
            "Nap",
            "Suave",
            "Moment Home",
            "Visco Form",
            "Form Flex",
            "Kios",
            "Faralya",
            "Kanaviçe",
            "Uğur",
          ],
          Giyim: [
            "Adidas",
            "Nap",
            "Brix",
            "Skechers",
            "Gezer",
            "Crocs",
            "Lacoste",
            "Familiss",
            "Under Armour",
            "Chirpy",
            "Penyem",
            "Aytuğ",
            "Kafkame",
            "Birkenstock",
            "Fly Soft",
            "Twigy",
            "Body Pure",
            "Penti",
            "Tommy Hilfiger",
            "The Don",
            "Novelido",
            "Futti",
            "Şüşütown",
            "Ipanema",
            "Nove Lido",
            "Yaase",
            "Unique",
            "Michael Kors",
            "Antonio Banderas",
            "Emporio Armani",
            "Sockstime",
            "Polo Ralph",
            "Quos",
            "Kaşgar",
            "Luca Dotti",
            "Sneakers Life",
            "My Evilla",
            "Zaxy Partner",
            "Thermoform",
            "Rider",
            "Disney",
            "Carnaval Socks",
            "Cartago",
            "Letoon",
            "Superman",
            "Vlc",
            "Tory Burch",
            "Snoopy",
            "Barbie",
            "Qmax",
            "Paw Patrol",
            "Looney Tunes",
            "Tabcom",
            "Winnie",
            "Batman",
            "Samteks",
            "Bugs Bunny",
            "Cozy Touch",
            "Rick and Morty",
            "Dumbo",
            "Daisy",
            "Ezelisa",
            "Minnie",
            "Tivoli",
            "Mickey Mouse",
            "John Ray",
            "Reyondan",
            "Exuma",
            "Cars",
            "Omax",
          ],
          Pil: [
            "Duracell",
            "Varta",
            "Energizer",
            "Kodak",
            "Forever Plus",
            "Eveready",
          ],
          Ampul: ["Panasonic", "Philips", "Wiz"],
          "Hırdavat ": [
            "Leva",
            "Mistique Home",
            "Nilson",
            "Boss Tape",
            "Viko",
            "Reyondan",
            "Pattex",
            "Sia",
            "Sista",
            "Command",
            "3M",
            "Scotch",
            "Tivoli",
            "Bic",
            "Bee Home",
            "Akfix",
            "Port-Bag",
            "Autokit",
            "Wd-40",
            "Home Puzzle",
            "Çınarteks",
            "Piranha",
            "Petrix",
          ],
          "Oto Aksesuar ": [
            "Autokit",
            "Otovınn",
            "Carpex",
            "Autofresh",
            "i'think",
            "Gojo",
            "Mistique Home",
            "Carcos",
            "Vavana",
            "Shine Bloom",
            "Boss Tape",
            "Matte",
            "Asterion",
            "Homebasix",
            "Falcon",
            "Bsg",
            "Magic Dose",
            "Goodyear",
            "Glade",
            "Evvon",
            "Febreze",
            "Piranha",
            "Auto Joy",
            "Leva",
            "Comrade",
          ],
          Çakmak: ["Bic", "Clipper", "Tokai", "Kasai"],
          "Kuponlar, Kartlar": [
            "Jet Foto ",
            "Tepe Gourmet",
            "S Sport Plus",
            "Düşyeri Yayınları",
            "İstanbul Kart",
          ],
        },
        "Kitap, Kırtasiye ve Oyuncak": {
          "Kitap, Dergi ve Gazete": [
            "Eksik Parça Yayınevi",
            "Reyondan",
            "Doğan Kitap",
            "İki A Yayıncılık",
            "Alfa Yayınları",
            "Kral Şakir",
            "Beta Yayıncılık",
            "Doğan Yayınları",
            "Doğan Egmont Yayıncılık",
            "Doğan Çocuk",
            "Disney",
            "Artemis Yayınları",
            "Maxi",
            "Yakamoz Yayınları",
            "Büyülü Fener",
            "Burda Dergisi",
            "İndigo Yayınları",
            "Mavi Çakra",
            "Everest Yayınları",
            "Heygirl",
            "Yediveren Yayınları",
            "Turkuvaz Dergi",
            "Doğan Burda Yayıncılık",
            "Haruki Murakami",
            "Lisanslı",
            "Aletha J. Solter",
            "Panini",
            "Çakıl Yayınları",
            "İnkılap Kitabevi",
            "Elle",
            "Leman",
            "Vogue",
            "Kpop",
            "Sofra",
            "Sia Kitap",
            "Yapı Kredi Yayınları",
            "Blue Jean",
            "Minika",
            "DK",
            "De Yayınevi",
            "Formsante",
            "Minecraft",
            "Mona Yayınevi",
            "Origami Türkiye",
            "Evim",
            "Lutka Yayın",
            "İskender Pala",
            "Posta Gazetesi",
            "Lokma",
            "Lol Surprise",
            "Amk Spor Gazetesi",
            "Lezzet",
            "Oksijen",
            "Level",
            "Bilim Çocuk",
            "İthaki Yayınları",
            "Bilim ve Teknik",
            "Kapı Yayınları",
            "GQ",
            "Şut ve Gol",
            "Alem",
            "Kolektif Kitap",
            "Chip",
            "Bayan Yanı",
            "Martı Yayınları",
            "Harper's Bazaar",
            "Kafkaokur",
            "Kafa",
            "Burda Yayıncılık",
            "Genç Motto",
            "Uykusuz",
            "OT",
            "Instyle",
            "Popular Science",
            "Atlas",
            "Tempo",
            "TRT Çocuk",
            "House Beautiful",
            "Trabzonspor",
            "Jean-Christophe Grange",
            "Nilüfer Devecigil",
            "My Little Pony",
            "Pop Up",
            "Takvim Gazetesi",
            "Akşam Gazetesi",
            "Sabah Gazetesi",
            "Sözcü Gazetesi",
            "Cumhuriyet Gazetesi",
            "Fanatik Gazetesi",
            "Hürriyet Gazetesi",
            "Milliyet Gazetesi",
            "Yenişafak Gazetesi",
            "Kahve",
            "Ayşe Kulin",
            "Gerçek Hayat",
            "Sia",
            "Innomag",
            "Bilge Çocuk",
            "Everest",
            "Fifa",
            "L'officiel",
            "Adel",
            "Nihayet",
            "Elele",
            "Ekonomist",
            "China Today Türkiye",
            "Business Leader",
            "Fast Company",
            "Şamdan",
            "Barbie",
            "Harvard Business Review",
            "Ayarsız",
            "Lol",
            "Pj Masks",
            "Tarih",
            "Post Öykü",
            "All About History",
            "Atlas Tarih",
            "Kriter",
            "Sezon Seyir Rehberi",
            "Gepettoys",
            "Bilge Minik",
            "Yacht Türkiye",
          ],
          Kırtasiye: [
            "Faber Castell",
            "Meteksan",
            "Frocx",
            "Kulinart",
            "Adel",
            "Fly",
            "Pritt",
            "Globox",
            "Edding",
            "Rotring",
            "Net",
            "Bic",
            "Mynote",
            "Reyondan",
            "Frozen",
            "Keskin Color",
            "Penmark",
            "Sharpie",
            "Pavv",
            "Barbie",
            "Stabilo",
            "Ark",
            "Spiderman",
            "Brick Bag",
            "School Time",
            "Notix",
            "Newell Rubbermaıd",
            "Südor",
            "Mydido",
            "Uhu",
            "Cars",
            "Hp",
            "Artikel",
            "Pilot",
            "Lisanslı",
            "Silka",
            "Smart",
            "Papermate",
            "Copier Bond",
            "Mikro",
            "Fjallraven",
            "Nba",
            "Eastpak",
            "Akar",
            "Rapoli",
          ],
          Oyuncak: [
            "Reyondan",
            "Lego",
            "Barbie",
            "Sunman",
            "Dede Oyuncak",
            "Simba",
            "Sluban",
            "M Toys",
            "Hot Wheels",
            "Disney",
            "Dickie Toys",
            "Polesie",
            "Fisher-Price",
            "Elmer's",
            "Play-Doh",
            "Zuru",
            "Goojitzu",
            "Mega",
            "Pilsan",
            "Ravensburger",
            "Art Puzzle",
            "Nerf",
            "Enchantimals",
            "Giochi Preziosi",
            "Mattel",
            "Polly Pocket",
            "Lol",
            "Let's be Child",
            "Tomy",
            "Marvel",
            "Hasbro",
            "Simba Baby",
            "Cry Babies",
            "Mega Bloks",
            "Elmers",
            "Adel",
            "Art Craft",
            "Teenage Mutant Ninja Turtles",
            "Monopoly",
            "Smoby",
            "Majorette",
            "My Little Pony",
            "Sensi",
            "Triathlon",
            "Adore",
            "Thomas & Friends",
            "Cicciobello",
            "Pj Masks",
            "Matchbox",
            "Minecraft",
            "Gepettoys",
            "Roblox",
            "Rainbow High",
            "Toybox",
            "Lollas",
            "Akedo",
            "Dc",
            "Goliath",
            "Schleich",
            "Scruff",
            "Giftölye",
            "Can Oyuncak",
            "Clover Squish",
            "Miraculous",
            "Harry Potter",
            "Pritt",
            "Jurassic World",
            "Petrichor",
            "Mercan",
            "Uno",
            "Teeny",
            "Lets",
            "Magic Mixlings",
            "Mila",
            "Kral Şakir",
            "Magic",
            "Topps",
            "Yappuzz",
            "Fashion Fidgets",
            "Citycode",
            "Rainbocorn",
            "Rapoli",
            "Fingerlings",
            "Anki",
            "Blingle Bands",
            "Warner Bros",
            "Küp-Tak",
            "Treasure X",
            "Artikel",
            "Squish",
            "Dıytoy",
            "Baby Alive",
            "Art",
            "Smartiga",
            "Harika Kanatlar",
            "Star Wars",
            "Ben 10",
            "Leva",
            "Cars",
            "Wee Baby",
            "Dino Ranch",
          ],
        },
        Çiçek: {
          "Canlı Bitki": ["Reyondan", "Ecoplant"],
          "Yapay Çiçek": ["Reyondan"],
        },
        "Pet Shop": {
          Köpek: [
            "Pedigree",
            "Bonnie",
            "Friskies",
            "Joker",
            "Bestpet",
            "Petvital",
            "Purina One",
            "Dost Pati",
            "Yepvital",
            "Dardanel",
          ],
          Kedi: [
            "Whiskas",
            "Felix",
            "Sheba",
            "Bonnie",
            "Gourmet",
            "Friskies",
            "Dreamies",
            "Perfect Fit",
            "Purina",
            "Bestpet",
            "Catwalk",
            "Akkum",
            "Gizmo",
            "Mır Mır",
            "Mastercat",
            "Dardanel",
            "Dost Pati",
            "Joker",
            "Doğalsan",
            "Proline",
          ],
          Kuş: ["Jungle", "Bonnie"],
          "Pet Aksesuarları": [
            "Petcraft",
            "Hipet",
            "Flip Pet",
            "Puppet",
            "Koroplast",
            "Cook",
            "Sleepy",
            "Jungle",
            "Pet Cetera",
            "Flexi",
          ],
        },
        Elektronik: {
          "Telefon ve Aksesuarları": [
            "i'think",
            "Xiaomi",
            "Upfull",
            "Panzerglass",
            "Ldnio",
            "Tecno",
            "Ttec",
            "Apple",
            "Samsung",
            "Reeder",
            "Realme",
            "Omix",
            "Hytech",
            "Markasız",
            "Philips",
            "Trident",
            "Hoco",
            "Nothing",
            "Swiss",
            "Varta",
            "Microcell",
            "Bood",
          ],
          "Bilgisayar ve Aksesuarları": [
            "Samsung",
            "Everest",
            "Sandisk",
            "Rampage",
            "Epson",
            "Getorix",
            "Tcl",
            "Xiaomi",
            "Apple",
            "Kingston",
            "Zyxel",
            "i'think",
            "Razer",
            "Canon",
          ],
          "Beyaz Eşya": [
            "Altus",
            "Samsung",
            "Hoover",
            "Seg",
            "Kumtel",
            "Simfer",
          ],
          "Elektrikli Ev Aletleri": [
            "Arzum",
            "Philips",
            "Kiwi",
            "Arnica",
            "Raks",
            "Grundig",
            "Fakir",
            "Sinbo",
            "Xiaomi",
            "Homend",
            "GoldMaster",
            "Tefal",
            "Kumtel",
            "Aprilla",
            "King",
            "Braun",
            "Tchibo",
            "Sunny",
            "Bood",
            "Roborock",
            "Russell Hobbs",
            "Fantom",
            "Samsung",
            "Altus",
            "Karcher",
            "Medisana",
            "Remington",
            "Wahl",
            "Cvs",
            "Dyson",
            "Singer",
            "Joysidea",
          ],
          "Oyun Konsolları": ["Xiaomi", "i'think"],
          "Görüntü ve Ses Sistemleri": [
            "JBL",
            "Xiaomi",
            "S-Link",
            "Apple",
            "Ttec",
            "Samsung",
            "S Sport Plus",
            "i'think",
            "Rasne",
            "Mikado",
            "Axen",
            "Bood",
            "Huawei",
            "Toshiba",
            "Seg",
            "Ldnio",
            "Everest",
            "Lg",
            "Tcl",
            "Vaeske",
            "Asonic",
            "Upfull",
            "Urban Mood",
            "GoldMaster",
            "Snopy",
            "Urban Sound",
            "Hytech",
          ],
          "Hobi ve Eğlence": ["Segway"],
          "Isıtma, Soğutma": [
            "Baymak",
            "Samsung",
            "Raks",
            "Kumtel",
            "Xiaomi",
            "General Home",
            "King",
            "Bood",
            "Dijitsu",
          ],
          "Aydınlatma, Elektrik Malzemeleri": [
            "Urban Mood",
            "Bood",
            "Philips",
            "Mystyle",
            "i'think",
            "General Home",
            "Nilson",
          ],
        },
      },
    };
  },

  computed: {
    allTrue() {
      return (
        this.barcode &&
        this.productname &&
        this.selectedCategory &&
        this.selectedSubCategory &&
        this.selectedBrand &&
        this.supplier &&
        this.unit &&
        this.quantity &&
        /^\d+(\.\d+)?$/.test(this.quantity) &&
        this.unitprice &&
        /^\d+(\.\d+)?$/.test(this.unitprice)
      );
    },
    getCategories() {
      return Object.keys(this.inventories);
    },
    getSubCategories() {
      return this.inventories?.[this.selectedCategory]
        ? Object.keys(this.inventories?.[this.selectedCategory])
        : [];
    },
    getSubCategoriesWithBrand() {
      return this.inventories?.[this.selectedCategory]?.[
        this.selectedSubCategory
      ]
        ? this.inventories?.[this.selectedCategory]?.[this.selectedSubCategory]
        : [];
    },
  },

  watch: {
    entryProductname(newVal) {
      this.productname = newVal;
    },
    entrySelectedCategory(newVal) {
      this.selectedCategory = newVal;
    },
    entrySelectedSubCategory(newVal) {
      this.selectedSubCategory = newVal;
    },
    entrySupplier(newVal) {
      this.supplier = newVal;
    },

    entrySelectedBrand(newVal) {
      this.selectedBrand = newVal;
    },
  },

  created() {
    this.toast = useToast();
  },

  methods: {
    ...mapActions("inventory", ["entryOne"]),

    entryKeyupEnter() {
      if (
        !!this.productname &&
        !!this.supplier &&
        !!this.quantity &&
        /^\d+(\.\d+)?$/.test(this.quantity) &&
        !!this.unitprice &&
        /^\d+(\.\d+)?$/.test(this.unitprice)
      ) {
        this.entryClick();
      } else {
        this.toast.error("Stok girişi sırasında bir hata oluştu!", {
          position: "bottom",
          duration: 2000,
        });
      }
    },

    async entryClick() {
      try {
        await this.entryOne({
          barcode: this.barcode,
          productname: this.capitalizeWords(this.productname),
          selectedCategory: this.selectedCategory,
          selectedSubCategory: this.selectedSubCategory,
          supplier: this.capitalizeWords(this.supplier),
          selectedBrand: this.selectedBrand,
          unit: this.unit,
          quantity: this.quantity,
          unitprice: this.unitprice,
        });

        this.toast.success("Stok girişi başarıyla gerçekleştirildi.", {
          position: "bottom",
          duration: 2000,
        });

        this.unit = "";
        this.quantity = "";
        this.unitprice = "";
        this.productname = "";
        this.selectedCategory = "";
        this.selectedSubCategory = "";
        this.selectedBrand = "";
        this.supplier = "";
        this.$emit("resetFields");
      } catch (error) {
        this.toast.error("Stok girişi sırasında bir hata oluştu!", {
          position: "bottom",
          duration: 2000,
        });
      }
    },

    capitalizeWords(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
};
</script>